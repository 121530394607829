@charset "UTF-8";
/* DO NOT EDIT -  AUTOMATICALLY GENERATED FROM: style.scss */
/*
 * Theme Name: gluck
 * Theme URI: none
 * Description: gluck theme
 * Version: 0.1
 * Author: k.i
 * Author URI:
 * Tags:
 * License: GNU General Public License v2.0 & Apache License 2.0
 * License URI: http://www.gnu.org/licenses/gpl-2.0.html http://www.apache.org/licenses/LICENSE-2.0
 */
/*
 * micro clearfix: http://nicolasgallagher.com/micro-clearfix-hack/
 */
/*
 * For modern browsers
 */
/* #447294 => #16a085 */
/* line 39, sass/style.scss */
.clearfix:after,
.clearfix:before {
    content: "";
    display: table;
}

/* line 43, sass/style.scss */
.clearfix:after {
    clear: both;
}

/*
 * For IE 6/7 (trigger hasLayout)
 */
/* line 49, sass/style.scss */
.clearfix {
    zoom: 1;
}

/*
 * wp-specific classes
 */
/* line 61, sass/style.scss */
.header {
/* margin-bottom :25px;  */
/* .navbar .nav > li > a{ */
/*     padding :0; */
/* } */
/* .navbar{ */
/*     margin-bottom :0; */
/* } */
/* .header-header-logo{ */
/*     height :139px; */
/*     width :129px; */
/* } */
/* .header-logo-wrapper{ */
/*     width :107px; */
/* } */
/* .header-header-about{ */
/*     height :header-sprite-height(header-about); */
/*     width :header-sprite-width(header-about); */
/* } */
/* .header-header-news{ */
/*     height :header-sprite-height(header-news); */
/*     width :header-sprite-width(header-news); */
/* } */
/* .header-header-event{ */
/*     height :header-sprite-height(header-event); */
/*     width :header-sprite-width(header-event); */
/* } */
/* .header-header-rent{ */
/*     height :header-sprite-height(header-rent); */
/*     width :header-sprite-width(header-rent); */
/* }     */
/* .header-header-contact{ */
/*     height :header-sprite-height(header-contact); */
/*     width :header-sprite-width(header-contact); */
/* } */
/* .header-links{ */
/*     margin-top :67px; */
/* } */
    position :relative;
}

/* line 102, sass/style.scss */
.footer {
/* margin-top :6px; */
}
/* line 105, sass/style.scss */
.footer .navbar .nav {
    margin: 0;
}
/* line 108, sass/style.scss */
.footer .navbar .nav > li > a {
    padding: 0;
}
/* line 111, sass/style.scss */
.footer .navbar {
    margin-bottom: 0;
}
/* line 115, sass/style.scss */
.footer .footer-footer-about-size {
    height: footer-sprite-height(footer-about);
    width: footer-sprite-width(footer-about);
}
/* line 120, sass/style.scss */
.footer .footer-footer-diary {
    height: footer-sprite-height(footer-diary);
    width: footer-sprite-width(footer-diary);
}
/* line 124, sass/style.scss */
.footer .footer-footer-event {
    height: footer-sprite-height(footer-event);
    width: footer-sprite-width(footer-event);
}
/* line 128, sass/style.scss */
.footer .footer-footer-rent {
    height: footer-sprite-height(footer-rent);
    width: footer-sprite-width(footer-rent);
}
/* line 132, sass/style.scss */
.footer .footer-footer-contact {
    height: footer-sprite-height(footer-contact);
    width: footer-sprite-width(footer-contact);
}
/* line 136, sass/style.scss */
.footer .footer-footer-logo {
    height: footer-sprite-height(footer-logo);
    width: 551px;
/* display :block; */
/* margin-left :46px; */
}
/* line 142, sass/style.scss */
.footer .footer-footer-logo-wrapper {
/* margin-left :300px;  */
}

/* @import "font-awesome.scss"; */
/* line 1, sass/_overide.scss */
/* .navbar .divider-vertical { */
/*   margin: 0; */
/*   border-left: 1px solid #dedede; */
/*   border-right: 1px solid #f2f2f2; */
/*   height: 40px; */
/* } */

/* line 7, sass/_overide.scss */
/* .navbar .nav > li > a { */
/*   float: none; */
/*   padding: 10px 35px; */
/*   text-shadow: 0 1px 0 #FFFFFF; */
/* } */


.navbar{
    /* background-color :black; */
}

.navbar in{
    background-color: white;
}
/* line 12, sass/_overide.scss */
.navbar-inner {
    
    background: #6ac2ba;
    background-color: #6ac2ba;
    border: 1px solid #6ac2ba;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff6ac2ba', endColorstr='#ff6ac2ba', GradientType=0);
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;



    
    min-height: 40px;
    padding-left: 0px;
    padding-right: 0px;
}

.navbar .nav > li > a {
    /* padding: 12px 17px 12px; */
    float: none;
    margin-top: 0px;
    text-transform: capitalize;
    font-weight: 400;
    color: #ffffff;
    text-decoration: none;
    text-shadow: none;
    transition: color 0.2s ease-in-out;
    -o-transition: color 0.2s ease-in-out;
    -moz-transition: color 0.2s ease-in-out;
    -webkit-transition: color 0.2s ease-in-out;
    /* font-size: 12px; */
}

.navbar .navbar-toggle{
    border-color :#fff;
}

.navbar .icon-bar{
    background-color: #fff;
}

.gl-navbar-brand{
    color :white;
}

.navbar-collapse {
    /* padding-right: 15px; */
    /* padding-left: 15px; */
    /* overflow-x: visible; */
    /* -webkit-overflow-scrolling: touch; */

    /* background-color: white; */
    
    /* border-top: 1px solid #fff; */
    /* border-top-width: 1px; */
    /* border-top-style: solid; */
    
    /* border-top-color: transparent; */
    /* -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,.1); */
    /* box-shadow: inset 0 1px 0 rgba(255,255,255,.1); */
}

/* .navbar-collapse a{ */
/*     color :black !important; */
    
/* } */


.nav .open > a, .nav .open > a:hover, .nav .open > a:focus {
    background-color: none;
    /* border-color: #337ab7; */
}

.navbar .nav > li > a:focus, .navbar .nav > li > a:hover {
    /* color: #eaeaea; */
    text-decoration: none;
    background-color: transparent;
    transition: color 0.1s ease-in-out;
    -o-transition: color 0.1s ease-in-out;
    -moz-transition: color 0.1s ease-in-out;
    -webkit-transition: color 0.1s ease-in-out;
}

/* ################ スマホナビゲーションメニュー ################ */
@media (max-width: 767px){
    
    .navbar-nav .open .dropdown-menu {
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: white !important;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    }
}




/* line 18, sass/_overide.scss */
[class^="icon-"], [class*=" icon-"] {
    background-image: none;
}

/* line 3, sass/_utils.scss */
.td-border-bottom {
    border-bottom: 1px solid #DDDDDD;
}

/* line 8, sass/_utils.scss */
.td-no-border-top {
    border-top: 0 !important;
}

/* line 12, sass/_utils.scss */
.padding-small {
    padding: 5px;
}

/* line 16, sass/_utils.scss */
.padding-middle {
    padding: 10px;
}

/* line 20, sass/_utils.scss */
.padding-large {
    padding: 20px;
}

/* line 24, sass/_utils.scss */
.margin-vertical-small {
    margin-top: 10px;
    margin-bottom: 10px;
}

/* line 29, sass/_utils.scss */
.margin-vertical-middle {
    margin-top: 20px;
    margin-bottom: 20px;
}

/* line 34, sass/_utils.scss */
.margin-vertical-middle25 {
    margin-top: 25px;
    margin-bottom: 25px;
}

/* line 39, sass/_utils.scss */
.margin-vertical-large {
    margin-top: 30px;
    margin-bottom: 30px;
}

/* line 45, sass/_utils.scss */
.margin-bottom-small {
    margin-bottom: 10px;
}

/* line 49, sass/_utils.scss */
.margin-bottom-middle {
    margin-bottom: 20px;
}

/* line 53, sass/_utils.scss */
.margin-bottom-large {
    margin-bottom: 30px;
}

/* line 58, sass/_utils.scss */
.margin-top-small {
    margin-top: 10px;
}

/* line 62, sass/_utils.scss */
.margin-top-middle {
    margin-top: 20px;
}

/* line 66, sass/_utils.scss */
.margin-top-large {
    margin-top: 30px;
}

/* line 72, sass/_utils.scss */
.no-horizontal-margin {
    margin-left: 0;
    margin-right: 0;
}

/* line 77, sass/_utils.scss */
.min-height-small {
    min-height: 100px;
}

/* line 81, sass/_utils.scss */
.font-smaller-middle {
    font-size: 0.7em;
}

/* line 84, sass/_utils.scss */
.no-float {
    float: none;
}

/* line 87, sass/_utils.scss */
.no-float {
    float: none;
}

/* line 90, sass/_utils.scss */
.float-left {
    float: left;
}

/* line 91, sass/_utils.scss */
.float-right {
    float: right;
}

/* line 92, sass/_utils.scss */
.display-none {
    display: none;
}

/* line 93, sass/_utils.scss */
.display-block {
    display: block;
}

/* line 94, sass/_utils.scss */
.list-style-none {
    list-style: none;
}

/* line 95, sass/_utils.scss */
.text-align-center {
    text-align: center;
}

/* line 96, sass/_utils.scss */
.text-align-left {
    text-align: left;
}

/* line 97, sass/_utils.scss */
.text-align-right {
    text-align: right;
}

/* line 98, sass/_utils.scss */
.margin-0 {
    margin: 0px;
}

/* line 99, sass/_utils.scss */
.margin-5 {
    margin: 5px;
}

/* line 100, sass/_utils.scss */
.margin-10 {
    margin: 10px;
}

/* line 101, sass/_utils.scss */
.margin-15 {
    margin: 15px;
}

/* line 102, sass/_utils.scss */
.margin-20 {
    margin: 20px;
}

/* line 103, sass/_utils.scss */
.margin-25 {
    margin: 25px;
}

/* line 104, sass/_utils.scss */
.margin-30 {
    margin: 30px;
}

/* line 105, sass/_utils.scss */
.margin-left-0 {
    margin-left: 0px;
}

/* line 106, sass/_utils.scss */
.margin-left-5 {
    margin-left: 5px;
}

/* line 107, sass/_utils.scss */
.margin-left-10 {
    margin-left: 10px;
}

/* line 108, sass/_utils.scss */
.margin-left-14 {
    margin-left: 14px;
}

/* line 109, sass/_utils.scss */
.margin-left-15 {
    margin-left: 15px;
}

/* line 110, sass/_utils.scss */
.margin-left-18 {
    margin-left: 18px;
}

/* line 111, sass/_utils.scss */
.margin-left-20 {
    margin-left: 20px;
}

/* line 112, sass/_utils.scss */
.margin-left-25 {
    margin-left: 25px;
}

/* line 113, sass/_utils.scss */
.margin-left-30 {
    margin-left: 30px;
}

/* line 114, sass/_utils.scss */
.margin-right-0 {
    margin-right: 0px;
}

/* line 115, sass/_utils.scss */
.margin-right-4 {
    margin-right: 4px;
}

/* line 116, sass/_utils.scss */
.margin-right-5 {
    margin-right: 5px;
}

/* line 117, sass/_utils.scss */
.margin-right-7 {
    margin-right: 7px;
}

/* line 118, sass/_utils.scss */
.margin-right-10 {
    margin-right: 10px;
}

/* line 119, sass/_utils.scss */
.margin-right-11 {
    margin-right: 11px;
}

/* line 120, sass/_utils.scss */
.margin-right-15 {
    margin-right: 15px;
}

/* line 121, sass/_utils.scss */
.margin-right-19 {
    margin-right: 19px;
}

/* line 122, sass/_utils.scss */
.margin-right-20 {
    margin-right: 20px;
}

/* line 123, sass/_utils.scss */
.margin-right-25 {
    margin-right: 25px;
}

/* line 124, sass/_utils.scss */
.margin-right-30 {
    margin-right: 30px;
}

/* line 125, sass/_utils.scss */
.margin-bottom-0 {
    margin-bottom: 0px;
}

/* line 126, sass/_utils.scss */
.margin-bottom-2 {
    margin-bottom: 2px;
}

/* line 127, sass/_utils.scss */
.margin-bottom-4 {
    margin-bottom: 4px;
}

/* line 128, sass/_utils.scss */
.margin-bottom-5 {
    margin-bottom: 5px;
}

/* line 129, sass/_utils.scss */
.margin-bottom-7 {
    margin-bottom: 7px;
}

/* line 130, sass/_utils.scss */
.margin-bottom-10 {
    margin-bottom: 10px;
}

/* line 131, sass/_utils.scss */
.margin-bottom-15 {
    margin-bottom: 15px;
}

/* line 132, sass/_utils.scss */
.margin-bottom-20 {
    margin-bottom: 20px;
}

/* line 133, sass/_utils.scss */
.margin-bottom-25 {
    margin-bottom: 25px;
}

/* line 134, sass/_utils.scss */
.margin-bottom-30 {
    margin-bottom: 30px;
}

/* line 135, sass/_utils.scss */
.margin-bottom-50 {
    margin-bottom: 50px;
}

/* line 136, sass/_utils.scss */
.margin-bottom-80 {
    margin-bottom: 80px;
}

/* line 137, sass/_utils.scss */
.margin-top-0 {
    margin-top: 0px;
}

/* line 138, sass/_utils.scss */
.margin-top-5 {
    margin-top: 5px;
}

/* line 139, sass/_utils.scss */
.margin-top-7 {
    margin-top: 7px;
}

/* line 140, sass/_utils.scss */
.margin-top-8 {
    margin-top: 8px;
}

/* line 141, sass/_utils.scss */
.margin-top-10 {
    margin-top: 10px;
}

/* line 142, sass/_utils.scss */
.margin-top-15 {
    margin-top: 15px;
}

/* line 143, sass/_utils.scss */
.margin-top-20 {
    margin-top: 20px;
}

/* line 144, sass/_utils.scss */
.margin-top-25 {
    margin-top: 25px;
}

/* line 145, sass/_utils.scss */
.margin-top-30 {
    margin-top: 30px;
}

/* line 146, sass/_utils.scss */
.margin-top-120 {
    margin-top: 120px;
}

/* line 147, sass/_utils.scss */
.padding-0 {
    padding: 0px;
}

/* line 148, sass/_utils.scss */
.padding-5 {
    padding: 5px;
}

/* line 149, sass/_utils.scss */
.padding-10 {
    padding: 10px;
}

/* line 150, sass/_utils.scss */
.padding-15 {
    padding: 15px;
}

/* line 151, sass/_utils.scss */
.padding-20 {
    padding: 20px;
}

/* line 152, sass/_utils.scss */
.padding-25 {
    padding: 25px;
}

/* line 153, sass/_utils.scss */
.padding-30 {
    padding: 30px;
}

/* line 154, sass/_utils.scss */
.padding-left-0 {
    padding-left: 0px;
}

/* line 155, sass/_utils.scss */
.padding-left-5 {
    padding-left: 5px;
}

/* line 156, sass/_utils.scss */
.padding-left-10 {
    padding-left: 10px;
}

/* line 157, sass/_utils.scss */
.padding-left-14 {
    padding-left: 14px;
}

/* line 158, sass/_utils.scss */
.padding-left-15 {
    padding-left: 15px;
}

/* line 159, sass/_utils.scss */
.padding-left-18 {
    padding-left: 18px;
}

/* line 160, sass/_utils.scss */
.padding-left-20 {
    padding-left: 20px;
}

/* line 161, sass/_utils.scss */
.padding-left-25 {
    padding-left: 25px;
}

/* line 162, sass/_utils.scss */
.padding-left-30 {
    padding-left: 30px;
}

/* line 163, sass/_utils.scss */
.padding-right-0 {
    padding-right: 0px;
}

/* line 164, sass/_utils.scss */
.padding-right-5 {
    padding-right: 5px;
}

/* line 165, sass/_utils.scss */
.padding-right-10 {
    padding-right: 10px;
}

/* line 166, sass/_utils.scss */
.padding-right-15 {
    padding-right: 15px;
}

/* line 167, sass/_utils.scss */
.padding-right-20 {
    padding-right: 20px;
}

/* line 168, sass/_utils.scss */
.padding-right-25 {
    padding-right: 25px;
}

/* line 169, sass/_utils.scss */
.padding-right-30 {
    padding-right: 30px;
}

/* line 170, sass/_utils.scss */
.padding-top-0 {
    padding-top: 0px;
}

/* line 171, sass/_utils.scss */
.padding-top-5 {
    padding-top: 5px;
}

/* line 172, sass/_utils.scss */
.padding-top-7 {
    padding-top: 7px;
}

/* line 173, sass/_utils.scss */
.padding-top-10 {
    padding-top: 10px;
}

/* line 174, sass/_utils.scss */
.padding-top-15 {
    padding-top: 15px;
}

/* line 175, sass/_utils.scss */
.padding-top-20 {
    padding-top: 20px;
}

/* line 176, sass/_utils.scss */
.padding-top-25 {
    padding-top: 25px;
}

/* line 177, sass/_utils.scss */
.padding-top-30 {
    padding-top: 30px;
}

/* line 178, sass/_utils.scss */
.padding-bottom-0 {
    padding-bottom: 0px;
}

/* line 179, sass/_utils.scss */
.padding-bottom-5 {
    padding-bottom: 5px;
}

/* line 180, sass/_utils.scss */
.padding-bottom-7 {
    padding-bottom: 7px;
}

/* line 181, sass/_utils.scss */
.padding-bottom-8 {
    padding-bottom: 8px;
}

/* line 182, sass/_utils.scss */
.padding-bottom-9 {
    padding-bottom: 9px;
}

/* line 183, sass/_utils.scss */
.padding-bottom-10 {
    padding-bottom: 10px;
}

/* line 184, sass/_utils.scss */
.padding-bottom-15 {
    padding-bottom: 15px;
}

/* line 185, sass/_utils.scss */
.padding-bottom-20 {
    padding-bottom: 20px;
}

/* line 186, sass/_utils.scss */
.padding-bottom-25 {
    padding-bottom: 25px;
}

/* line 187, sass/_utils.scss */
.padding-bottom-30 {
    padding-bottom: 30px;
}

/* line 155, sass/style.scss */
.about .hajimeni {
    margin-bottom: 25px;
}
/* line 158, sass/style.scss */
.about .word-wrapper {
    margin-left: 162px;
    margin-top: 13px;
}
/* line 162, sass/style.scss */
.about .word-wrapper1 {
    margin-left: 102px;
    margin-top: 23px;
}

/* line 169, sass/style.scss */
.contact .telimg {
    margin-left: 119px;
}
/* line 172, sass/style.scss */
.contact .text {
    margin-left: 295px;
}
/* line 178, sass/style.scss */
.contact .dd {
    text-align: left;
}
/* line 181, sass/style.scss */
.contact .text-unit {
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 25px;
}

/* line 188, sass/style.scss */
.page-template-page-top-php {
    background-color: #004896;
}

/* line 191, sass/style.scss */
.page-template-page-index-php {
    background-color: #004896;
}

/* line 194, sass/style.scss */
.center {
    /* text-align :center; */
    margin-left: auto;
    margin-right: auto;
}

/* line 199, sass/style.scss */
.top {
/* margin-top :100px; */
/* margin-left :200px; */
/* min-height :600px; */
/* margin :100px auto 0; */
}
/* line 204, sass/style.scss */
.top .tab-content {
    overflow: hidden;
}
/* line 207, sass/style.scss */
.top .top-show-wrapper {
    margin-top: 125px;
    margin-left: 200px;
    min-height: 600px;
}
/* line 212, sass/style.scss */
.top .event-news {
    width: 138px;
}
/* line 214, sass/style.scss */
.top .event-news .text-wrapper {
    margin-top: 30px;
}
/* line 217, sass/style.scss */
.top .event-news .text {
    font-size: 12px;
}
/* line 221, sass/style.scss */
.top .imgholder {
    margin-left: 28px;
}
/* line 224, sass/style.scss */
.top .body {
    width: 460px;
}
/* line 227, sass/style.scss */
.top .logo {
    margin-top: 58px;
    width: 163px;
}
/* line 231, sass/style.scss */
.top .nav > li > a:hover {
    background-color: inherit;
    text-decoration: none;
}
/* line 235, sass/style.scss */
.top .bg-hukidashi {
    min-height: 600px;
    padding-top: 50px;
    background-image: url(images/index-fukidashi_03.gif);
    background-repeat: no-repeat;
    background-position: 112px 10px;
}
/* line 243, sass/style.scss */
.top .topmenu-about {
    height: topmenu-sprite-height(about);
    width: topmenu-sprite-width(about);
}
/* line 247, sass/style.scss */
.top .topmenu-news {
    height: topmenu-sprite-height(news);
    width: topmenu-sprite-width(news);
}
/* line 251, sass/style.scss */
.top .topmenu-event {
    height: topmenu-sprite-height(event);
    width: topmenu-sprite-width(event);
}
/* line 255, sass/style.scss */
.top .topmenu-rent {
    height: topmenu-sprite-height(rent);
    width: topmenu-sprite-width(rent);
}
/* line 259, sass/style.scss */
.top .topmenu-contact {
    height: topmenu-sprite-height(contact);
    width: topmenu-sprite-width(contact);
}

/* line 268, sass/style.scss */
.news .category .text {
    border-bottom: 1px solid #000;
    padding-bottom: 2px;
}
/* line 274, sass/style.scss */
.news .new_entry .text {
    border-bottom: 1px solid #000;
    padding-bottom: 2px;
}
/* line 279, sass/style.scss */
.news .menu {
    width: 180px;
    margin-left: 60px;
}
/* line 283, sass/style.scss */
.news .content {
    margin-bottom: 50px;
}
/* line 285, sass/style.scss */
.news .content p {
    font-size: 14px;
    padding: 0 0px;
    margin-bottom: 25px;
}
/* line 291, sass/style.scss */
.news .entries {
    font-size: 12px;
}

/* line 297, sass/style.scss */
.event .entries-wrapper {
    margin-top: 55px;
}
/* line 300, sass/style.scss */
.event .entry {
    font-size: 12px;
}
/* line 304, sass/style.scss */
.event img {
    height: none;
}
/* line 309, sass/style.scss */
.event .event-img {
    margin-right: 20px;
}
/* line 313, sass/style.scss */
.event .event-unit p {
    margin: 0 0 10px;
}
/* line 317, sass/style.scss */
.event .tab-content {
    border: 1px solid #ccc;
    min-height: 113px;
    position: relative;
    margin-left: 30px;
    /* padding-left :30px;  */
    padding-bottom: 30px;
}
/* line 325, sass/style.scss */
.event .tabnav {
    position: absolute;
    bottom: 0px;
    right: 30px;
    border-bottom: none;
}
/* line 330, sass/style.scss */
.event .tabnav li {
    text-decoration: none;
    list-style: none;
}

/* line 338, sass/style.scss */
.rent .fb-button {
    margin-top: 15px;
    margin-bottom: 60px;
}
/* line 346, sass/style.scss */
.rent .hotel-text .content {
    line-height: 26px;
    margin-left: 4px;
}
/* line 350, sass/style.scss */
.rent .hotel-text .notice {
    line-height: 25px;
    margin-left: 4px;
}
/* line 356, sass/style.scss */
.rent .towf .mapholder {
    width: 440px;
}
/* line 359, sass/style.scss */
.rent .towf .imgs {
    margin-top: 40px;
    width: 460px;
}
/* line 363, sass/style.scss */
.rent .towf .slides-controller {
    margin-top: 5px;
}
/* line 370, sass/style.scss */
.rent .restaurant .content {
    line-height: 26px;
    margin-left: 19px;
}
/* line 378, sass/style.scss */
.rent .raunge .imgs {
    margin-top: 22px;
}
/* line 381, sass/style.scss */
.rent .raunge .slides-controller {
    margin-top: 5px;
}
/* line 384, sass/style.scss */
.rent .raunge .rest-img {
    margin-top: 117px;
}
/* line 388, sass/style.scss */
.rent .nyuukyo-text {
    margin-left: 19px;
}
/* line 394, sass/style.scss */
.rent .access .gmap {
    margin-top: 5px;
    /* width: 520px; */
}

.gmap {
    /* position: relative; */
    /* padding-top: 75%; */
    /* height: 0; */
    /* overflow: hidden; */
}
.gmap iframe{
    max-width:100%
    ;height:300px;
    /* position: absolute; */
    /* top: 0; */
    /* left: 0; */
    /* width: 100%; */
    /* height: 100%; */
}



/* line 398, sass/style.scss */
.rent .access .map-text {
    margin-bottom: 15px;
}
/* line 400, sass/style.scss */
.rent .access .map-text .text {
    margin-top: 9px;
    margin-left: 42px;
}

/* line 408, sass/style.scss */
h1, h2, h3 {
    font-size: 18px;
    line-height: 18px;
}

/* line 413, sass/style.scss */
h1, h2, h3, h4, h5, h6 {
    /* color: inherit; */
    /* font-family: inherit; */
    color: #777777;
    font-weight: bold;
/* margin: 0; */
/* text-rendering: optimizelegibility; */
}

/* line 422, sass/style.scss */
h1, h2, h3, h4, h5, h6, p {
    margin: 0 0 15px;
}

/* line 426, sass/style.scss */
#test-linear-gradient {
    *zoom: 1;
  filter: progid:DXImageTransform.Microsoft.gradient(gradientType=0, startColorstr='#FFFFFFFF', endColorstr='#FFDDDDDD');
  background-image: -webkit-gradient(linear, 0% 0%, 100% 100%, color-stop(0%, #ffffff), color-stop(100%, #dddddd));
  background-image: -webkit-linear-gradient(left top, #ffffff, #dddddd);
  background-image: -moz-linear-gradient(left top, #ffffff, #dddddd);
  background-image: -o-linear-gradient(left top, #ffffff, #dddddd);
  background-image: linear-gradient(left top, #ffffff, #dddddd);
}

/*
 * for fun
 */
/* * { */
/*   -webkit-transition: all 0.2s ease; */
/*   -moz-transition: all 0.2s ease; */
/*   -ms-transition: all 0.2s ease; */
/*   -o-transition: all 0.2s ease; } */
/*
  layout
  */
/* line 446, sass/style.scss */
body {
    padding-top: 0;
}

/*
 * Admin Top Navigation fixes
 */
/* line 452, sass/style.scss */
body.admin-bar .navbar-fixed-top {
    top: 28px;
}

/* 004896 top base color */
/* midashi */
/* line 458, sass/style.scss */
.midashi-red {
    position: relative;
    color: #ffffff;
    background: #d44637;
    font-size: 16pt;
    border: 0px solid #ff6347;
    border-radius: 3px;
    margin: 0px 10px 20px 0px;
    padding: 10px 5px 10px 10px;
}

/* line 469, sass/style.scss */
.midashi-red:after, .midashi-red:before {
    content: "";
    position: absolute;
    top: 100%;
    height: 0;
    width: 0;
}

/* line 476, sass/style.scss */
.midashi-red:after {
    left: 40px;
    border: 10px solid transparent;
    border-top: 10px solid #d44637;
}

/* line 481, sass/style.scss */
.midashi-red:before {
    left: 40px;
    border: 10px solid transparent;
    border-top: 10px solid #ff6347;
}

/* line 488, sass/style.scss */
.midashi-orange {
    position: relative;
    color: #ffffff;
    background: #e67e22;
    font-size: 16pt;
    border: 0px solid #ff6347;
    margin: 10px 10px 20px 0px;
    padding: 10px 5px 10px 10px;
    border-radius: 3px;
}

/* line 498, sass/style.scss */
.midashi-orange:after, .midashi-orange:before {
    content: "";
    position: absolute;
    top: 100%;
    height: 0;
    width: 0;
}

/* line 505, sass/style.scss */
.midashi-orange:after {
    left: 40px;
    border: 10px solid transparent;
    border-top: 10px solid #e74c3c;
}

/* line 510, sass/style.scss */
.midashi-orange:before {
    left: 40px;
    border: 10px solid transparent;
    border-top: 10px solid #ff6347;
}

/* line 517, sass/style.scss */
.midashi-blog-title {
    /* color: #ffffff ; */
    /* background: #fff; */
    /* font-size: 16pt ; */
    /* line-height: 1; */
    margin: 10px 0px;
    padding: 10px 5px 10px 5px;
    /* box-shadow:1px 3px 7px 0px #666666 ; */
    border-bottom: 5px solid #f0f0f0;
/* border-radius: 0px ; */
}

/* blog */
/* line 532, sass/style.scss */
.blog-content {
    margin: 10px 0px;
    padding: 10px 0px;
    padding: 10px 5px 0px 5px;
}

/* line 538, sass/style.scss */
.copyright {
    font-size: 12px;
}

/* MOBILE */
/* line 545, sass/style.scss */
.mobile-html {
    font-size: 62.5%;
    height: 100%;
    min-height: 100%;
}

/* line 565, sass/style.scss */
 body, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    /* background: transparent;  */
    text-rendering: optimizeLegibility;
}

html {
    font-size :62.5% !important;
}

/* line 576, sass/style.scss */
body {
    font-weight: 400;
    /* text-rendering: optimizeLegibility;  */
    margin: 0;
    /* font-family: "nimbus-sans", "Helvetica Neue", sans-serif; */
    font-family: 'ヒラギノ角ゴ ProN W3','Hiragino Kaku Gothic ProN','メイリオ' Meiryo,'Lucida Grande',sans-serif ;
    
    /* font-family: "Montserrat","游ゴシック",YuGothic,"ヒラギノ角ゴ ProN W3","Hiragino Kaku Gothic ProN","メイリオ",Meiryo,sans-serif; */
    /* font-family: "游ゴシック",YuGothic,"ヒラギノ角ゴ ProN W3","Hiragino Kaku Gothic ProN","メイリオ",Meiryo,sans-serif; */
    
    /* font-size: 16px; */
    font-size: 1.6rem;
    letter-spacing : 0.03em;
    line-height: 24px;
    color: #737373;
/* text-rendering: optimizeLegibility; */
/* font-smooth: always; */
/* -webkit-font-smoothing: antialiased; */
/* height: 100% !important; */
/* min-height: 100% !important; */
}

/* line 592, sass/style.scss */
h1, h2, h3, h4, h5, h6 {
    line-height: 1.2;
    font-weight: 500;
    color: #555555;
/* font-family: "nimbus-sans", "Helvetica Neue", sans-serif;  */
}

@font-face {
    font-family: 'Entypo';
    src: url("./fonts/entypo/entypo.eot");
    /* IE9 Compat Modes */
    src: url("./fonts/entypo/entypo.eot?") format("embedded-opentype"), url("./fonts/entypo/entypo.woff") format("woff"), url("./fonts/entypo/entypo.ttf") format("truetype"), url("./fonts/entypo/entypo.svg") format("svg");
/* Legacy iOS */
}

@font-face {
    font-family: 'entypo-social';
    src: url("./fonts/entypo-social/entypo-social.eot");
    /* IE9 Compat Modes */
    src: url("./fonts/entypo-social/entypo-social.eot?") format("embedded-opentype"), url("./fonts/entypo-social/entypo-social.woff") format("woff"), url("./fonts/entypo-social/entypo-social.ttf") format("truetype"), url("./fonts/entypo-social/entypo-social.svg") format("svg");
/* Legacy iOS */
}

/* line 613, sass/style.scss */
i {
    font-style: normal;
}

/* line 616, sass/style.scss */
.icon {
    font-family: 'Entypo';
    font-size: 40px;
    font-size: 4rem;
    font-style: normal;
}

/* line 622, sass/style.scss */
.social-icon {
    font-family: 'entypo-social';
    font-style: normal;
    line-height: .5;
    font-size: 50px;
    font-size: 5rem;
}

/* line 629, sass/style.scss */
.social-icons {
    padding: 4rem 0;
    font-family: 'entypo-social';
    font-size: 80px;
    font-size: 8rem;
}

/* line 634, sass/style.scss */
.social-icons ul {
    text-align: center;
    margin: 0 auto;
}

/* line 638, sass/style.scss */
.social-icons ul li {
    display: inline-block;
    margin: 0;
    font-size: normal;
}

/* line 642, sass/style.scss */
.social-icons ul li a {
    color: #999999;
}

/* line 645, sass/style.scss */
.social-links {
    position: absolute;
    list-style: none;
    padding: 1rem;
}

@media (min-width: 767px) {
    /* line 650, sass/style.scss */
    .social-links {
        bottom: 0;
    }
}
/* line 652, sass/style.scss */
.social-links li {
    display: inline-block;
    padding: 0 2%;
}

@media (min-width: 767px) {
    /* line 656, sass/style.scss */
    .social-links li {
        padding: 0 4%;
    }
}
/* line 658, sass/style.scss */
.social-links li:first-of-type {
    padding-left: 0;
}

/* line 660, sass/style.scss */
.social-links li a {
    color: #999999;
    text-decoration: none;
}

/* line 665, sass/style.scss */
.menuWrap {
    position: fixed;
    background: #ecf0f1;
    z-index: 999999;
    width: 220px;
    left: -220px;
    height: 100%;
    -webkit-transition: left 0.3s ease-in-out;
    -moz-transition: left 0.3s ease-in-out;
    -o-transition: left 0.3s ease-in-out;
    transition: left 0.3s ease-in-out;
    color: #34495e;
    opacity: 0.9;
    filter: alpha(opacity=90);
}

/* line 680, sass/style.scss */
.menuWrap nav {
    background: #ecf0f1;
    height: 100%;
    float: left;
    font-family: "nimbus-sans", "Helvetica Neue", sans-serif;
    width: 100%;
}

/* line 686, sass/style.scss */
.menuWrap nav header {
    padding: 1em 8%;
}

/* line 688, sass/style.scss */
.menuWrap nav ul {
    background: transparent;
}

/* line 690, sass/style.scss */
.menuWrap nav ul li {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

/* line 695, sass/style.scss */
.menuWrap nav ul li.active, .menuWrap nav ul li:hover {
    background: #6ac2ba;
}

/* line 697, sass/style.scss */
.menuWrap nav ul li.active a, .menuWrap nav ul li.active .icon, .menuWrap nav ul li:hover a, .menuWrap nav ul li:hover .icon {
    color: #34495e;
}

/* line 700, sass/style.scss */
.menuWrap nav ul li a {
    padding: 1em 8%;
    color: #34495e;
    display: block;
    text-decoration: none;
}

/* line 705, sass/style.scss */
.menuWrap nav .icon {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    vertical-align: middle;
    font-size: 30px;
    font-size: 3rem;
    margin-right: 5%;
    color: #4f4f4f;
}

/* line 715, sass/style.scss */
.menuWrap .logo a {
    text-decoration: none;
}

/* line 717, sass/style.scss */
.menuWrap .logo h1 {
    color: white;
    font-size: 16px;
    font-size: 1.6rem;
    font-weight: bold;
    margin-bottom: 0;
}

/* line 723, sass/style.scss */
.menuWrap.open {
    left: 0;
}

/* line 725, sass/style.scss */
.menuWrap span {
    display: block;
    position: absolute;
    background: white;
    float: right;
    right: -48px;
    top: 0;
    padding: .8em 4.7%;
    cursor: pointer;
    text-align: center;
    color: #333333;
}

/* line 736, sass/style.scss */
.menuWrap .icon {
    vertical-align: middle;
    font-size: 60px;
    font-size: 6rem;
}

/* line 741, sass/style.scss */
.menuTop {
    background: #2a2a2a;
    color: #333333;
    position: relative;
    padding: 1em 8%;
}

/* mobile top */
/* line 749, sass/style.scss */
.in-slide-content {
    color: #333;
    float: right;
    font-family: 'Helvetica Neue', helvetica;
    font-size: 60px;
    font-weight: bold;
    right: 0;
    margin: 40px;
    padding: 20px;
    position: absolute;
    top: 0;
    width: 700px;
    z-index: 9999;
    /* Show above .gradient */
    text-shadow: 0 1px 0 #fff;
    -webkit-font-smoothing: antialiased;
}

/* line 767, sass/style.scss */
.siteIntro {
    display :flex;
    align-items: center;

    align-content: center;
    
    /* flex-direction  :column; */
    flex-wrap: wrap;
    
    
    font-family: "Helvetica Neue","nimbus-sans",  sans-serif;
    /* background: url("images/GluckPhoto/DSC01185.png") no-repeat center center !important; */
    /* -webkit-background-size: cover; */
    /* -moz-background-size: cover; */
    /* -o-background-size: cover; */
    /* background-size: cover; */
    /* overflow: hidden;  */
    /* cpied */
    /* color: #333333; */
    /* float: right; */
    /* font-family: 'Helvetica Neue',helvetica; */
    /* font-size: 60px; */
    /* font-weight: bold; */
    /* text-shadow: 0 1px 0 #FFFFFF; */
    /* top: 0; */
    /* width: 700px; */
    /* height: 100%; */
    /* 半透明 文字の可読性を確保 */
    position: relative;
    color: white;
    height: 100%;
    width: 100%;
    top: 0;
    margin-top: 0px;
    text-align: center;
    margin-right: auto !important;
    margin-left: auto !important;
    z-index: 9999;
    /* Show above .gradient */
    text-shadow: 0 1px 0 #fff;
    -webkit-font-smoothing: antialiased;
    /* height: 300px; */
    /* top: 50%; */
    /* margin-top: -100px; */
    text-align: center;
}

.siteIntro h1 img{
    width :auto;
    max-width :25vw;
    max-width :25vmin;

}

/* line 816, sass/style.scss */
#maximage {
    height: 100%;
    width: 100%;
/* position :relative; */
}

.siteIntro  {
    background-color:rgba(0,0,0,0.2);
}

.siteIntro  h1 img{
    /* background-color:rgba(0,0,0,0.2); */
}

.siteIntro .zwei-logo-css{
    color: #1F94C6;
    font-family: "Varela Round script=all rev=5", "Adobe Blank";
    font-weight: 400;
    font-style: normal;
    font-size :60px;
    text-shadow: none;
}


/* line 822, sass/style.scss
a:link {
    text-decoration: none;
}

/* line 825, sass/style.scss */
a:visited {
    text-decoration: none;
}

/* line 828, sass/style.scss */
a:hover {
    text-decoration: none;
}

/* line 831, sass/style.scss */
a:active {
    text-decoration: none;
}

/* line 835, sass/style.scss */
.siteIntro article {
    

    
    opacity: 1;
    filter: alpha(opacity=100);
    /* z-index: 999999; */
    -webkit-animation: opac 2s forwards;
    /* IE 10+ */
    animation-delay: .6s;
    -webkit-animation-delay: .6s;
    /* Safari and Chrome */
    -moz-animation: opac 2s forwards;
    /* IE 10+ */
    -moz-animation-delay: .6s;
    /* Safari and Chrome */
    animation: opac 2s forwards;
    /* IE 10+ */
    animation-delay: .6s;
    /* opacity: 0; */
    width: 100%;
    text-align: center;


    /* position: absolute; */
    /* color: white; */
    /* height: 300px; */
    /* top: 25%; */
    margin: -20vh 0 0 0px;

    
}
/* line 866, sass/style.scss */
.siteIntro article a {
    color: #6ac2ba;
}
/* line 869, sass/style.scss */
.siteIntro article a:hover {
    color: #6ac2ba;
}
/* line 873, sass/style.scss */
.siteIntro article a.btn {
    /* font-size: 16px; */
    font-size: 3vw;
    font-size: 3vmin;

    
    color: white;
}

.siteIntro-btn-box-top{
    margin-top: 3vmin;
}

/* line 878, sass/style.scss */
.siteIntro article h1 {
    /* font-size: 45px; */
    margin: 0px 0 0px 0;
    padding: 0px;
    /* font-weight: 600; */
    /* color: #ecf0f1;  */
    color: #6ac2ba;
}

/* line 887, sass/style.scss */
.siteIntro article h2 {
    margin-bottom: 20px;
}

/* line 890, sass/style.scss */
.no-text-shadow {
    text-shadow: none;
}

/* line 893, sass/style.scss */
.siteIntro article h2 .title {
    font-size: 18px;
    margin: .5rem 0 10px 0;
    font-weight: 600;
    color: #ecf0f1;
    vertical-align: middle;
    text-shadow: 1px 2px 0 #2c3e50, 1px -2px 0 #2c3e50, -1px 2px 0 #2c3e50, -1px -2px 0 #2c3e50, 2px 1px 0 #2c3e50, 2px -1px 0 #2c3e50, -2px 1px 0 #2c3e50, -2px -1px 0 #2c3e50, 1px 1px 0 #2c3e50, 1px -1px 0 #2c3e50, -1px 1px 0 #2c3e50, -1px -1px 0 black;
    filter: progid:DXImageTransform.Microsoft.Glow(Color=$base-deep-blue, Strength=1);
    -ms-filter: "progid:DXImageTransform.Microsoft.dropshadow(OffX=1, OffY=-1, Color=$base-deep-blue)progid:DXImageTransform.Microsoft.dropshadow(OffX=-1, OffY=-1, Color=$base-deep-blue)progid:DXImageTransform.Microsoft.dropshadow(OffX=1, OffY=0, Color=$base-deep-blue)progid:DXImageTransform.Microsoft.dropshadow(OffX=-1, OffY=0, Color=$base-deep-blue)progid:DXImageTransform.Microsoft.dropshadow(OffX=0, OffY=1, Color=$base-deep-blue)progid:DXImageTransform.Microsoft.dropshadow(OffX=1, OffY=1, Color=$base-deep-blue)progid:DXImageTransform.Microsoft.dropshadow(OffX=-1, OffY=1, Color=$base-deep-blue)";
}

/* line 910, sass/style.scss */
.siteIntro article p {
    font-size: 3vw;
    font-size: 3vmin;


    
    margin: .5rem 0 0 0;
    font-weight: 600;
    line-height: 1.5;
    color: #ecf0f1;
    vertical-align: middle;
    text-shadow: 1px 2px 0 #2c3e50, 1px -2px 0 #2c3e50, -1px 2px 0 #2c3e50, -1px -2px 0 #2c3e50, 2px 1px 0 #2c3e50, 2px -1px 0 #2c3e50, -2px 1px 0 #2c3e50, -2px -1px 0 #2c3e50, 1px 1px 0 #2c3e50, 1px -1px 0 #2c3e50, -1px 1px 0 #2c3e50, -1px -1px 0 black;
    filter: progid:DXImageTransform.Microsoft.Glow(Color=$base-deep-blue, Strength=1);
    -ms-filter: "progid:DXImageTransform.Microsoft.dropshadow(OffX=1, OffY=-1, Color=$base-deep-blue)progid:DXImageTransform.Microsoft.dropshadow(OffX=-1, OffY=-1, Color=$base-deep-blue)progid:DXImageTransform.Microsoft.dropshadow(OffX=1, OffY=0, Color=$base-deep-blue)progid:DXImageTransform.Microsoft.dropshadow(OffX=-1, OffY=0, Color=$base-deep-blue)progid:DXImageTransform.Microsoft.dropshadow(OffX=0, OffY=1, Color=$base-deep-blue)progid:DXImageTransform.Microsoft.dropshadow(OffX=1, OffY=1, Color=$base-deep-blue)progid:DXImageTransform.Microsoft.dropshadow(OffX=-1, OffY=1, Color=$base-deep-blue)";
}

@media (min-width: 767px) {
    /* line 924, sass/style.scss */
    .siteIntro article h1 {
        font-size: 70px;
    }
}
@media (min-width: 767px) {
    /* line 928, sass/style.scss */
    .siteIntro article p {
        /* font-size: 20px; */
        /* font-size: 2rem; */
    }
}
/* line 932, sass/style.scss */
figure.logo {
    width: 60px;
    margin: 0 auto;
}

/* line 936, sass/style.scss */
.tweet {
    text-align: center;
    color: #999999;
    padding: 1rem 0;
}

@media (min-width: 767px) {
    /* line 941, sass/style.scss */
    .tweet {
        text-align: left;
        padding: 2rem 0;
    }
}
/* line 944, sass/style.scss */
.tweet .social-icon {
    color: #4099ff;
    margin-bottom: 1rem;
}

@media (min-width: 767px) {
    /* line 948, sass/style.scss */
    .tweet .social-icon {
        float: left;
        margin-right: 2%;
    }
}
/* maximage */
/* line 954, sass/style.scss */
#maximage {
    /* Only use this if you fade it in again after the images load */
    display: none;
    z-index: 99999;
/* position:fixed !important; */
}

/* line 961, sass/style.scss */
.mc-cycle {
    position: relative !important;
}

/* line 965, sass/style.scss */
#cycle-loader {
    height: 32px;
    left: 50%;
    margin: -8px 0 0 -8px;
    position: absolute;
    top: 50%;
    width: 32px;
    z-index: 999;
}

/* line 975, sass/style.scss */
.gradient {
    opacity: 0.3;
    filter: alpha(opacity=300);
    left: 0;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 99;
}

/* line 986, sass/style.scss */
.gl-tweets {
/* margin :0 0px; */
}

/* line 990, sass/style.scss */

.gl-container{
    overflow: hidden;
    
}


@media screen and (min-width:768px){
    /*　画面サイズが768px以上の場合読み込む　*/
    .gl-container {
        /* background-image:url(./images/bg-1.png); */
        background-image: url(./images/Gluck_Web_back.png);
        background-position: 0 0;
        background-repeat: repeat-x repeat-y,repeat-y;
    }
    /* line 997, sass/style.scss */
    .gl-container .header {
        position: relative;
        margin-top: 5px;
        margin-bottom: 0px;
    }
}

/* line 1002, sass/style.scss */
.gl-container .header .header-text {
    position: absolute;
    top: 50%;
    left: 32%;
    line-height: 0;
    font-size: 230%;
    font-weight: 700;
}

/* line 1015, sass/style.scss */
.gl-title {
    color: #7f8c8d;
    vertical-align: middle;
    text-shadow: 1px 2px 0 #ecf0f1, 1px -2px 0 #ecf0f1, -1px 2px 0 #ecf0f1, -1px -2px 0 #ecf0f1, 2px 1px 0 #ecf0f1, 2px -1px 0 #ecf0f1, -2px 1px 0 #ecf0f1, -2px -1px 0 #ecf0f1, 1px 1px 0 #ecf0f1, 1px -1px 0 #ecf0f1, -1px 1px 0 #ecf0f1, -1px -1px 0 black;
    filter: progid:DXImageTransform.Microsoft.Glow(Color=$base-grey-lite, Strength=1);
    -ms-filter: "progid:DXImageTransform.Microsoft.dropshadow(OffX=1, OffY=-1, Color=$base-grey-lite)progid:DXImageTransform.Microsoft.dropshadow(OffX=-1, OffY=-1, Color=$base-grey-lite)progid:DXImageTransform.Microsoft.dropshadow(OffX=1, OffY=0, Color=$base-grey-lite)progid:DXImageTransform.Microsoft.dropshadow(OffX=-1, OffY=0, Color=$base-grey-lite)progid:DXImageTransform.Microsoft.dropshadow(OffX=0, OffY=1, Color=$base-grey-lite)progid:DXImageTransform.Microsoft.dropshadow(OffX=1, OffY=1, Color=$base-grey-lite)progid:DXImageTransform.Microsoft.dropshadow(OffX=-1, OffY=1, Color=$base-grey-lite)";
}

/* line 1024, sass/style.scss */
.gl-single p {
    margin-bottom: 10px;
}

.gl-single img{
    display: block;
    height: auto;
    max-width: 100%;
}

/* line 1029, sass/style.scss */
.gl-content {
    background-color: #fff;
    padding: 0 5%;
}
/* line 1032, sass/style.scss */
.gl-content ol {
    list-style-type: none;
}
/* line 1037, sass/style.scss */
.gl-content .title {
    padding-bottom :5px;
    color: #7f8c8d;
    /* #4F9B92 */
    /* line-height: 32px; */
    border-bottom: 3px solid #6ac2ba;
    /* padding: 0px 5px; */
    
    /* background: transparent;/\*背景透明に*\/ */
    /* border-left: solid 5px #6ac2ba;/\*左線*\/ */

    font-size: 2.5rem;
    font-weight: 700;




    
    vertical-align: middle;
    /* text-shadow: 1px 2px 0 #ecf0f1, 1px -2px 0 #ecf0f1, -1px 2px 0 #ecf0f1, -1px -2px 0 #ecf0f1, 2px 1px 0 #ecf0f1, 2px -1px 0 #ecf0f1, -2px 1px 0 #ecf0f1, -2px -1px 0 #ecf0f1, 1px 1px 0 #ecf0f1, 1px -1px 0 #ecf0f1, -1px 1px 0 #ecf0f1, -1px -1px 0 black; */
    filter: progid:DXImageTransform.Microsoft.Glow(Color=$base-grey-lite, Strength=1);
    -ms-filter: "progid:DXImageTransform.Microsoft.dropshadow(OffX=1, OffY=-1, Color=$base-grey-lite)progid:DXImageTransform.Microsoft.dropshadow(OffX=-1, OffY=-1, Color=$base-grey-lite)progid:DXImageTransform.Microsoft.dropshadow(OffX=1, OffY=0, Color=$base-grey-lite)progid:DXImageTransform.Microsoft.dropshadow(OffX=-1, OffY=0, Color=$base-grey-lite)progid:DXImageTransform.Microsoft.dropshadow(OffX=0, OffY=1, Color=$base-grey-lite)progid:DXImageTransform.Microsoft.dropshadow(OffX=1, OffY=1, Color=$base-grey-lite)progid:DXImageTransform.Microsoft.dropshadow(OffX=-1, OffY=1, Color=$base-grey-lite)";
}
/* line 1049, sass/style.scss */
.gl-content .content {
    font-size: 122.923%;
    line-height: 1.3;
    margin-bottom: 12px;
}
/* line 1055, sass/style.scss */
.gl-content .article-title {
    font-size: 137.5%;
}

/* line 1061, sass/style.scss */
.gl-blog .content {
    margin-top: 20px;
}

/* line 1066, sass/style.scss */
.gl-mobile .gl-content {
    padding: 0px !important;
}

/* line 1070, sass/style.scss */
.gl-mobile .gl-container-inner {
    padding: 0px 10px;
}

/* STAFF */
/* line 1080, sass/style.scss */
.gl-staff article {
    margin: 0 0 50px 0;
}
/* line 1083, sass/style.scss */
.gl-staff .msg {
    margin: 0 0 30px 0;
}


.gl-staff h3{
    font-size :20px;
    margin-bottom :10px;
}

.gl-staff p{
    margin-bottom :25px;
}

.gl-staff-second-h2{
    margin-top :35px;
}



/* page navi */
/* line 1090, sass/style.scss */
.wp-pagenavi {
    clear: both;
    margin: 0 auto;
    text-align: center;
}

/* line 1096, sass/style.scss */
.wp-pagenavi a, .wp-pagenavi span {
    text-decoration: none;
    border: 1px solid #BFBFBF;
    padding: 3px 5px;
    margin: 2px;
}

/* line 1103, sass/style.scss */
.wp-pagenavi a:hover, .wp-pagenavi span.current {
    border-color: #000;
}

/* line 1107, sass/style.scss */
.wp-pagenavi span.current {
    font-weight: bold;
}

/* line 1111, sass/style.scss */
.top-scroll-icon {
    width :100%;
    z-index: 9999999;
    color: #6ac2ba;

    font-size: 4.5vmin;
    
    margin-top :10vmin;


    -webkit-animation: sdb 1.3s linear infinite;
    animation: sdb 1.3s linear infinite;
    
    position: absolute;
    bottom: 8vh;
    /* left: 65%; */

    
/* margin:-100px 0 0 0px; */
}
/* line 1122, sass/style.scss */
.top-scroll-icon a:link {
    color: #6ac2ba;
}
/* line 1125, sass/style.scss */
.top-scroll-icon a:visited {
    color: #6ac2ba;
}
/* line 1129, sass/style.scss */
.top-scroll-icon .icon {
    font-size: 500%;
}

/* line 1135, sass/style.scss */
/* .gl-mobile .top-scroll-icon { */
/*     font-size: 110%; */
/*     top: 90%; */
/*     left: 60%; */
/* } */

/* line 1142, sass/style.scss */
.text-shadow-deep-blue {
    text-shadow: 1px 2px 0 #2c3e50, 1px -2px 0 #2c3e50, -1px 2px 0 #2c3e50, -1px -2px 0 #2c3e50, 2px 1px 0 #2c3e50, 2px -1px 0 #2c3e50, -2px 1px 0 #2c3e50, -2px -1px 0 #2c3e50, 1px 1px 0 #2c3e50, 1px -1px 0 #2c3e50, -1px 1px 0 #2c3e50, -1px -1px 0 black;
    filter: progid:DXImageTransform.Microsoft.Glow(Color=$base-deep-blue, Strength=1);
    -ms-filter: "progid:DXImageTransform.Microsoft.dropshadow(OffX=1, OffY=-1, Color=$base-deep-blue)progid:DXImageTransform.Microsoft.dropshadow(OffX=-1, OffY=-1, Color=$base-deep-blue)progid:DXImageTransform.Microsoft.dropshadow(OffX=1, OffY=0, Color=$base-deep-blue)progid:DXImageTransform.Microsoft.dropshadow(OffX=-1, OffY=0, Color=$base-deep-blue)progid:DXImageTransform.Microsoft.dropshadow(OffX=0, OffY=1, Color=$base-deep-blue)progid:DXImageTransform.Microsoft.dropshadow(OffX=1, OffY=1, Color=$base-deep-blue)progid:DXImageTransform.Microsoft.dropshadow(OffX=-1, OffY=1, Color=$base-deep-blue)";
}

/* relative post */
/* line 1153, sass/style.scss */
.relative-post ol {
    list-style-type: none;
}
/* line 1156, sass/style.scss */
.relative-post li {
    /* font-size :160%; */
    list-style-type: none;
    margin-bottom: 20px;
}

/* line 1163, sass/style.scss */
.color-red {
    color: #e74c3c;
}

/* line 1167, sass/style.scss */
.color-green {
    color: #6ac2ba;
}

/* line 1171, sass/style.scss */
.footer-box {
    margin: 0px 0 20px 0;
}

/* android */
/* line 1178, sass/style.scss */
.ar-black-shadow {
    text-rendering: auto !important;
    color: white !important;
    font-family: 'ヒラギノ角ゴ ProN W3','Hiragino Kaku Gothic ProN','メイリオ' Meiryo,'Lucida Grande',sans-serif;
    font-size: 13px;
    line-height: 1.5;
    display: inline-block;
    height: 32px;
    margin-right: 20px;
    line-height: 32px;
    font-size: 184.61538%;
    font-weight: 700;
    color: #fff;
    vertical-align: middle;
    text-shadow: 1px 2px 0 black, 1px -2px 0 black, -1px 2px 0 black, -1px -2px 0 black, 2px 1px 0 black, 2px -1px 0 black, -2px 1px 0 black, -2px -1px 0 black, 1px 1px 0 black, 1px -1px 0 black, -1px 1px 0 black, -1px -1px 0 black;
    zoom: 1;
    filter: progid:DXImageTransform.Microsoft.Glow(Color=black, Strength=1);
    -ms-filter: "progid:DXImageTransform.Microsoft.dropshadow(OffX=1, OffY=-1, Color=black)progid:DXImageTransform.Microsoft.dropshadow(OffX=-1, OffY=-1, Color=black)progid:DXImageTransform.Microsoft.dropshadow(OffX=1, OffY=0, Color=black)progid:DXImageTransform.Microsoft.dropshadow(OffX=-1, OffY=0, Color=black)progid:DXImageTransform.Microsoft.dropshadow(OffX=0, OffY=1, Color=black)progid:DXImageTransform.Microsoft.dropshadow(OffX=1, OffY=1, Color=black)progid:DXImageTransform.Microsoft.dropshadow(OffX=-1, OffY=1, Color=black)";
}

/* line 1212, sass/style.scss */
.android html, .android body, .android span, .android object, .android iframe,
.android h1, .android h2, .android h3, .android h4, .android h5, .android h6, .android p, .android blockquote, .android pre,
.android abbr, .android address, .android cite, .android code,
.android del, .android dfn, .android em, .android img, .android ins, .android kbd, .android q, .android samp,
.android small, .android strong, .android sub, .android sup, .android var,
.android b, .android i,
.android dl, .android dt, .android dd, .android ol, .android ul, .android li,
.android fieldset, .android form, .android label, .android legend,
.android table, .android caption, .android tbody, .android tfoot, .android thead, .android tr, .android th, .android td,
.android aside, .android canvas, .android details, .android figcaption, .android figure,
.android footer, .android header, .android hgroup, .android menu, .android nav, .android section, .android summary,
.android time, .android mark, .android audio, .android video {
    text-rendering: auto !important;
}

/* line 1217, sass/style.scss */
.tablet {
    font-size: 14px;
}
/* line 1220, sass/style.scss */
.tablet .siteIntro article {
    top: 25%;
}

/* line 1230, sass/style.scss */
.article-summary .content-border-center, .article-header:hover .article-summary .content-border-center, .news-header:hover .article-summary .content-border-center {
    position: absolute;
    top: 0;
    left: 200px;
    background-image: url(/assets/images/svg/news_border/center.png);
    height: 266px;
    -webkit-transition: background-image 300ms;
    -moz-transition: background-image 300ms;
    -o-transition: background-image 300ms;
    transition: background-image 300ms;
}

/* line 1241, sass/style.scss */
.article-summary .content-border-right, .article-header:hover .article-summary .content-border-right, .news-header:hover .article-summary .content-border-right {
    position: absolute;
    top: 0;
    width: 200px;
    height: 266px;
    -webkit-transition: background-image 300ms;
    -moz-transition: background-image 300ms;
    -o-transition: background-image 300ms;
    transition: background-image 300ms;
}

/* line 1252, sass/style.scss */
.gl-blog-list .title {
    border: none !important;
    font-size: 14px;
}
/* line 1256, sass/style.scss */
.gl-blog-list ul, .gl-blog-list li {
    margin: 0;
    padding: 0;
    list-style-type: none;
}
/* line 1261, sass/style.scss */
.gl-blog-list .article-summary > a {
    display: block;
    width: 100%;
    height: 100%;
    color: #7f8c8d;
}
/* line 1268, sass/style.scss */
.gl-blog-list .article-summary {
    border: solid 4px #6ac2ba;
    border-radius: 4px;
    margin-bottom: 30px;
    position: relative;
    height: 218px;
}
/* line 1275, sass/style.scss */
.gl-blog-list .article-summary .gl-article-summary-content-wrapper {
    display: table;
    width: 100%;
    height: 100%;
    padding: 0 30px;
}
/* line 1281, sass/style.scss */
.gl-blog-list .article-summary .gl-article-summary-content-wrapper .gl-article-summary-content {
    position: relative;
    display: table-cell;
    height: 100%;
    vertical-align: middle;
}
/* line 1289, sass/style.scss */
.gl-blog-list .gl-social-wrapper {
    margin-bottom: 20px;
}
/* line 1294, sass/style.scss */
.gl-blog-list time {
    display: inline-block;
    height: 32px;
    margin-right: 20px;
    line-height: 32px;
    font-size: 20px;
    font-weight: 700;
    color: #fff;
    vertical-align: middle;
    text-shadow: 1px 2px 0 black, 1px -2px 0 black, -1px 2px 0 black, -1px -2px 0 black, 2px 1px 0 black, 2px -1px 0 black, -2px 1px 0 black, -2px -1px 0 black, 1px 1px 0 black, 1px -1px 0 black, -1px 1px 0 black, -1px -1px 0 black;
    zoom: 1;
    filter: progid:DXImageTransform.Microsoft.Glow(Color=black, Strength=1);
    -ms-filter: "progid:DXImageTransform.Microsoft.dropshadow(OffX=1, OffY=-1, Color=black)progid:DXImageTransform.Microsoft.dropshadow(OffX=-1, OffY=-1, Color=black)progid:DXImageTransform.Microsoft.dropshadow(OffX=1, OffY=0, Color=black)progid:DXImageTransform.Microsoft.dropshadow(OffX=-1, OffY=0, Color=black)progid:DXImageTransform.Microsoft.dropshadow(OffX=0, OffY=1, Color=black)progid:DXImageTransform.Microsoft.dropshadow(OffX=1, OffY=1, Color=black)progid:DXImageTransform.Microsoft.dropshadow(OffX=-1, OffY=1, Color=black)";
}

/* line 1312, sass/style.scss */
.gl-time-text {
    display: inline-block;
    height: 32px;
    margin-right: 20px;
    line-height: 32px;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    vertical-align: middle;
    text-shadow: 1px 2px 0 black, 1px -2px 0 black, -1px 2px 0 black, -1px -2px 0 black, 2px 1px 0 black, 2px -1px 0 black, -2px 1px 0 black, -2px -1px 0 black, 1px 1px 0 black, 1px -1px 0 black, -1px 1px 0 black, -1px -1px 0 black;
    zoom: 1;
    filter: progid:DXImageTransform.Microsoft.Glow(Color=black, Strength=1);
    -ms-filter: "progid:DXImageTransform.Microsoft.dropshadow(OffX=1, OffY=-1, Color=black)progid:DXImageTransform.Microsoft.dropshadow(OffX=-1, OffY=-1, Color=black)progid:DXImageTransform.Microsoft.dropshadow(OffX=1, OffY=0, Color=black)progid:DXImageTransform.Microsoft.dropshadow(OffX=-1, OffY=0, Color=black)progid:DXImageTransform.Microsoft.dropshadow(OffX=0, OffY=1, Color=black)progid:DXImageTransform.Microsoft.dropshadow(OffX=1, OffY=1, Color=black)progid:DXImageTransform.Microsoft.dropshadow(OffX=-1, OffY=1, Color=black)";
}

/* wp_social_bookmarking_light */
/* line 1328, sass/style.scss */
.wp_social_bookmarking_light {
    border: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
}

/* line 1333, sass/style.scss */
.wp_social_bookmarking_light div {
    float: left !important;
    border: 0 !important;
    padding: 0 !important;
    margin: 0 5px 0px 0 !important;
    height: 23px !important;
    text-indent: 0 !important;
}

/* line 1341, sass/style.scss */
.wp_social_bookmarking_light img {
    border: 0 !important;
    padding: 0;
    margin: 0;
    vertical-align: top !important;
}

/* line 1347, sass/style.scss */
.wp_social_bookmarking_light_clear {
    clear: both !important;
}

/* line 1350, sass/style.scss */
.wsbl_twitter {
    width: 100px;
}

/* line 1355, sass/style.scss */
.gl-event .gl-time-text {
    font-size: 105.5%;
}

/* line 1359, sass/style.scss */
.gl-navbar {
    margin: 5px 0 20px 0;
}

/* line 1364, sass/style.scss */
.gaiyou dt {
/* float: left; */
/* width: 10%; */
/* clear: left; */
/* text-align: left; */
/* overflow: none; */
/* text-overflow: ellipsis; */
/* white-space: nowrap; */
}

/* line 1373, sass/style.scss */
.gaiyou dd {
    /* margin-left :15%; */
    margin-bottom: 10px;
}

/* line 1378, sass/style.scss */
.gl-home-share {
    margin-bottom: 70px;
}

/* .gl-mobile .gl-catch-img{ */
/*     width :20%; */
/* } */
/* .gl-mobile{ */
/*     .gl-blog-list .article-summary .gl-article-summary-content-wrapper { */
/*         padding : 0 5% !important; */
/*     } */
/* } */
/* line 1394, sass/style.scss */
.gl-catch-img {
    /* float :left; */
    /* width :130px; */
    border: 0px solid #f58bac;
    border-radius: 3px;
/* height :130px; */
/* overflow:hidden; */
/* display:table; */
/* display:table-cell; */
/* text-align:center; */
/* vertical-align:middle; */
/* layout-grid-line:130px; */
}
/* line 1412, sass/style.scss */
.gl-catch-img img {
    border: 3px solid #f58bac;
    border-radius: 3px;
    vertical-align: middle;
}

/* line 1420, sass/style.scss */
.gl-list-left {
    /* width :130px !important; */
    /* float :left; */
    display: table-cell;
    vertical-align: middle;
    padding-left: 10px;
}

/* line 1429, sass/style.scss */
.gl-mobile .gl-list-left {
    width: 86px !important;
}

/* line 1433, sass/style.scss */
.gl-list-right {
    /* width :70% !important; */
    /* margin-left :96px !important; */
    padding-left: 10px;
    /* width :50% !important; */
    height: 130px;
    display: table-cell;
    vertical-align: middle;
}

/* line 1444, sass/style.scss */
.gl-list-right-inner {
    display: inline-block;
}

/* line 1449, sass/style.scss */
.gl-event-list .article-summary {
    border: solid 4px #6ac2ba;
    border-radius: 4px;
    margin-bottom: 30px;
    /* padding-top :5%;  */
    position: relative;
    height: 150px;
}
/* line 1457, sass/style.scss */
.gl-event-list .article-summary .gl-article-summary-content-wrapper {
    display: table;
    width: 100%;
    height: 100%;
    padding: 0 !important;
}
/* line 1463, sass/style.scss */
.gl-event-list .article-summary .gl-article-summary-content-wrapper .gl-article-summary-content {
    position: relative;
    display: table-cell;
    height: 100%;
    vertical-align: middle;
}

/* line 1475, sass/style.scss */
.gl-mobile .gl-list-right {
    min-width: 200px;
    height: 130px !important;
}
/* line 1478, sass/style.scss */
.gl-mobile .gl-list-right .title {
    font-size: 100%;
}

/* line 1484, sass/style.scss */
.gl-tyusyaku {
    color: #3498db;
    font-size: 90%;
}

/* line 1490, sass/style.scss */
.gl-qpn a {
/* color: #FFF; */
/* text-decoration: none; */
/* background-color: $base-orange; */
/* width: 140px; */
/* /\* color: #666666; *\/ */
/* text-align: center; */
/* display: block; */
/* padding-top: 2px; */
/* padding-right: 5px; */
/* padding-bottom: 2px; */
/* padding-left: 5px; */
/* font-weight: normal; */
}
/* line 1504, sass/style.scss */
.gl-qpn .sml {
    font-size: 12px;
}

/* line 1509, sass/style.scss */
.gl-more {
    font-size: 15px;
    line-height: 27px;
    margin-bottom: 20px;
    margin-top: 10px;
}
/* line 1514, sass/style.scss */
.gl-more a {
    color: #FFF;
    text-decoration: none;
    background-color: #f3f3f3;
    width: 90px;
    color: #666666;
    text-align: center;
    display: block;
    padding-top: 2px;
    padding-right: 5px;
    padding-bottom: 2px;
    padding-left: 5px;
    font-weight: normal;
}

/* line 1530, sass/style.scss */
.gl-more-old {
    font-size: 15px;
    line-height: 27px;
    margin-bottom: 20px;
    margin-top: 10px;
}
/* line 1535, sass/style.scss */
.gl-more-old a {
    color: #FFF;
    text-decoration: none;
    background-color: #e67e22;
    width: 140px;
    /* color: #666666; */
    text-align: center;
    display: block;
    padding-top: 2px;
    padding-right: 5px;
    padding-bottom: 2px;
    padding-left: 5px;
    font-weight: normal;
}

/* line 1551, sass/style.scss */
.kuzu .breadcrumbs {
    font-size: 13px;
    padding-bottom: 10px;
}

/* line 1557, sass/style.scss */
div#breadcrumb {
    padding: 0 0 0px;
    margin: 0 0 10px;
    clear: both;
}

/* line 1562, sass/style.scss */
div#breadcrumb a {
    font-color: #cccccc;
}

/* line 1565, sass/style.scss */
div#breadcrumb div {
    display: inline;
    font-size: 13px;
}

/* line 1569, sass/style.scss */
#breadcrumb div a {
    color: #666;
    text-decoration: none;
}

/* hukidasi */
/* <div class="fbox"> */
/* あいうえおあいうえおあいうえお */
/* </div> */
/* line 1579, sass/style.scss */
.hukidashi .img-box {
/* width :20%; */
/* float :left; */
}
/* line 1583, sass/style.scss */
.hukidashi .img-box img {
    margin: 0;
    padding: 0;
    border: 3px solid #6ac2ba;
    border-radius: 3px;
}
/* line 1590, sass/style.scss */
.hukidashi .img {
    /* border: 3px solid $base-green; */
    /* border-radius: 3px;  */
    /* vertical-align :middle; */
    margin: 0;
    padding: 0;
/* height :100px; */
/* max-height :100px; */
/* width :20%; */
}
/* line 1602, sass/style.scss */
.hukidashi .text {
/* width :73%; */
}
/* line 1605, sass/style.scss */
.hukidashi .fbox {
    font-size: 80%;
    line-height: 1.3;
    position: relative;
    display: inline-block;
    padding: 0.7em;
    text-align: left;
    background-color: #ffffff;
    border: 3px solid #f58bac;
    margin: 0 0 0 20px;
    border-radius: 4px;
}
/* line 1617, sass/style.scss */
.hukidashi .fbox:after, .hukidashi .fbox:before {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
}
/* line 1623, sass/style.scss */
.hukidashi .fbox:after {
    top: 2px;
    left: -19px;
    border: 11px solid transparent;
    border-right: 16px solid #ffffff;
/*13px*/
}
/* line 1629, sass/style.scss */
.hukidashi .fbox:before {
    top: 5px;
    left: -19px;
    border: 8px solid transparent;
    border-right: 8px solid #f58bac;
/*10px*/
}

/* line 1639, sass/style.scss */
.hukidashi-migi .img-box {
/* width :20%; */
/* float :left; */
}
/* line 1643, sass/style.scss */
.hukidashi-migi .img-box img {
    margin: 0;
    padding: 0;
    border: 3px solid #6ac2ba;
    border-radius: 3px;
}
/* line 1650, sass/style.scss */
.hukidashi-migi .img {
    /* border: 3px solid $base-green; */
    /* border-radius: 3px;  */
    /* vertical-align :middle; */
    margin: 0;
    padding: 0;
/* height :100px; */
/* max-height :100px; */
/* width :20%; */
}
/* line 1662, sass/style.scss */
.hukidashi-migi .fbox {
    font-size: 80%;
    line-height: 1.3;
    position: relative;
    display: inline-block;
    padding: 0.7em;
    text-align: left;
    background-color: #ffffff;
    border: 3px solid #f58bac;
    margin: 0 20px 0 0px;
    border-radius: 4px;
}
/* line 1674, sass/style.scss */
.hukidashi-migi .fbox:after, .hukidashi-migi .fbox:before {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
}
/* line 1680, sass/style.scss */
.hukidashi-migi .fbox:after {
    top: 2px;
    right: -19px;
    border: 11px solid transparent;
    border-left: 16px solid #ffffff;
/*13px*/
}
/* line 1686, sass/style.scss */
.hukidashi-migi .fbox:before {
    top: 5px;
    right: -19px;
    border: 8px solid transparent;
    border-left: 8px solid #f58bac;
/*10px*/
}

/* /\* migi *\/ */
/* .hukidashi-migi{ */
/* .fbox { */
/*     position: relative; */
/*     display:inline-block; */
/*     padding:10px ; */
/*     text-align:left; */
/*     background-color:#ffffff; */
/*     border:1px solid #6ac2ba; */
/*     margin: 5px 10px 5px 5px; */
/*     border-radius: 4px ; */
/* } */
/* .fbox:after, .fbox:before { */
/*     content: ""; */
/*     position: absolute; */
/*     height: 0; */
/*     width: 0; */
/* } */
/* .fbox:after { */
/*     top: 2px; */
/*     right: -21px; */
/*     border: 11px solid transparent; */
/*     border-left: 16px solid #ffffff; /\*13px*\/ */
/* } */
/* .fbox:before { */
/*     top: 3px; */
/*     right: -21px; */
/*     border: 10px solid transparent; */
/*     border-left: 10px solid #6ac2ba; /\*10px*\/ */
/* } */
/* } */
/* sns */
/* line 1728, sass/style.scss */
.gl-snsbox {
    margin-top: 50px;
    margin-bottom: 30px;
}
/* line 1731, sass/style.scss */
.gl-snsbox .hoso {
    margin-bottom: 10px;
    color: #f58bac;
}

/* line 1736, sass/style.scss */
.sns {
    padding: 5px 5px 5px 5px;
    bottom: 10px;
    /* position: fixed; */
    border-radius: 0px 4px 4px 0px;
    /* CSS3 */
    -moz-border-radius: 7px;
    /* Firefox */
    -webkit-border-radius: 7px;
    border: 1px solid #ecf0f1;
    background-image: url(images/a30.png);
    background-repeat: repeat;
}

/* line 1749, sass/style.scss */
.sns li {
    margin: 5px 0;
}

/* line 1752, sass/style.scss */
.sns h3 {
    margin-left: 7px;
    padding: 0;
    color: #dddddd;
    line-height: 120%;
    font-size: 18px;
    font-weight: bold;
}

/* line 1760, sass/style.scss */
.sns .snsb li {
    float: left;
    margin-right: 15px;
    list-style-type: none;
}

/* line 1765, sass/style.scss */
.snsb li {
    float: left;
    margin-right: 10px;
    list-style-type: none;
}

/* line 1772, sass/style.scss */
.gl-mobile .sns {
    bottom: 18px;
    /* position: fixed; */
    z-index: 9998;
    border-radius: 0px 4px 4px 0px;
    /* CSS3 */
    -moz-border-radius: 7px;
    /* Firefox */
    -webkit-border-radius: 7px;
    border-top-width: 1px;
    border-left-width: 1px;
    border-bottom-width: 1px;
    border-right-width: 1px;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-style: solid;
    border-right-style: solid;
    border-top-color: #ccc;
    border-bottom-color: #ccc;
    border-left-color: #ccc;
    border-right-color: #ccc;
    background-image: url(images/a30.png);
    background-repeat: repeat;
    padding-top: 3px;
    padding-right: 5px;
    padding-bottom: 3px;
    padding-left: 15px;
/* margin-left: -10px; */
}
/* line 1799, sass/style.scss */
.gl-mobile .sns li {
    margin: 5px 0;
    margin-right: 5px;
}
/* line 1803, sass/style.scss */
.gl-mobile .sns h3 {
    margin-left: 7px;
    z-index: 9999;
    padding: 0;
    color: #dddddd;
    line-height: 120%;
    font-size: 18px;
    font-weight: bold;
}
/* line 1812, sass/style.scss */
.gl-mobile .sns03 .snsb {
    padding-top: 10px;
    padding-right: 0px;
    padding-bottom: 10px;
    padding-left: 0px;
    margin-right: -5px;
    margin-left: -5px;
}



/* ################ TWITTER ################ */

.timeline-Header{
    display: none;
}


/* ################ 本日のＤＪ ################ */
.today table{
    font-size: 1.4rem;
    table-layout: fixed;

    max-width :100%;
    min-width :100%;
}
.today tr{
    
}
.today td{
    word-wrap:break-word;
    overflow-wrap : break-word;

    padding :10px 0;
}

.today tr td:first-child {
    width: 185px;
}


/* ################ デスクトップ イベント情報 ################ */
.eventinfo-card {
    /* border: solid 4px #6ac2ba; */
    /* border-radius: 4px; */
    /* margin-bottom: 30px; */
    /* padding: 15px; */

    border: solid 3px #6ac2ba;
    border-radius: 4px;
    margin-bottom: 30px;
    padding: 15px;
/* padding-top: 5%; */
/* position: relative; */
/* height: 150px; */
}



.eventinfo-card-tachikawa{
    border: solid 3px #1E9FBD;
}


.gl-article-meta{
    font-weight: bold;
    /* font-size: 2.6rem; */
    /* font-size: 2.4vmin; */
    font-size: 1.5rem;
}


.gl-article-meta .gl-date{
    color: #5DB7AC;/*文字色*/
    border: solid 1px #5DB7AC;/*線色*/
    padding: 0.3rem;/*文字周りの余白*/
    border-radius: 0.15em;/*角丸*/

    /* PC */
    /* font-size :1.6vmin; */
    
    font-size :11px;
}

.eventinfo-card-tachikawa .gl-article-meta .gl-date{
    color: #1E9FBD;/*文字色*/
    border: solid 1px #B7E3EC;/*線色*/
}


.gl-article-meta .gl-date-box{
    /* margin-bottom :10px; */

}

.eventinfo-card .gl-article-meta{
    /* background: linear-gradient(transparent 70%, #B7E3EC 70%); */
    position: relative;
    /* padding-bottom :0.7rem; */

    
}

.eventinfo-card .gl-article-meta-title{
    border-bottom: solid 1px #5DB7AC;
}

.eventinfo-card-tachikawa  .gl-article-meta-title{
    border-bottom: solid 1px #1E9FBD !important;
}


/* .eventinfo-card .gl-article-meta:after{ */
/*     content: ""; */
/*     position: absolute; */
/*     left: 0; */
/*     bottom: 0; */
/*     width: 100%; */
/*     height: 7px; */
/*     /\* background: -webkit-repeating-linear-gradient(-44deg, #5DB7AC, #5DB7AC 2px, #fff 2px, #fff 4px); *\/ */
/*     /\* background: repeating-linear-gradient(-44deg, #5DB7AC, #5DB7AC 2px, #fff 2px, #fff 4px); *\/ */

    
/* } */

.eventinfo-card-tachikawa .gl-article-meta{
    /* background: linear-gradient(transparent 70%, #B7E3EC 70%); */
    position: relative;
    padding-bottom :1rem;
}

.eventinfo-card-tachikawa .gl-article-meta:after{
    /* content: ""; */
    /* position: absolute; */
    /* left: 0; */
    /* bottom: 0; */
    /* width: 100%; */
    /* height: 7px; */
    /* background: -webkit-repeating-linear-gradient(-44deg, #B7E3EC, #B7E3EC 2px, #fff 2px, #fff 4px); */
    /* background: repeating-linear-gradient(-44deg, #B7E3EC, #B7E3EC 2px, #fff 2px, #fff 4px); */
}

.eventinfo-card-flex {
    display :flex;
    align-items: center;
}

.eventinfo-card p{
    color: #737373;
    font-size: 1.6rem;
}


.eventinfo-card a{
    display: block;
    color: #7f8c8d;
    text-decoration: none;
    
}
.eventinfo-card img{
    vertical-align :middle;
    border: 2px solid #f58bac;
    border-radius: 3px;
    vertical-align: middle;
    padding :0px;


}

.eventinfo-card-flex-img{
    width :230px;
}



.eventinfo-card figure {
    /* width: 200px; */
    /* height: 162px; */
    float: left;
    /* box-shadow: 0px 5px 20px rgba(90,90,90,0.1); */
    /* background: #999999; */
    overflow: hidden;
}

.eventinfo-card .text{
    margin: 0px 0px 0px 40px;
    flex: 1 0 0;
}

/* ################ mobile イベント情報 ################ */
@media (max-width: 767px) {
    .eventinfo-card .text{
        margin: 0px 0px 0px 20px;
        font-size: 1.3rem;        
    }

    .eventinfo-card-flex-img{
        width :80px;
    }
    .eventinfo-card .gl-article-meta{

        margin-bottom :0;
    }
    .eventinfo-card p {
        color: #737373;
        font-size: 1.1rem;
        font-size: 2.1vmin;
    }    
}



/* ################ TWEI ################ */
.gl-twei img{
    display: block;
    max-width: 100%;
    height: auto;
}


/* ################ 固定ページ ################ */
.gl-content h2 {
    padding-bottom :5px;
    color: #7f8c8d;
    /* #4F9B92 */
    /* line-height: 32px; */
    border-bottom: 3px solid #6ac2ba;
    /* padding: 0px 5px; */
    
    /* background: transparent;/\*背景透明に*\/ */
    /* border-left: solid 5px #6ac2ba;/\*左線*\/ */

    font-size: 2.5rem;
    font-weight: 700;
    vertical-align: middle;
    /* text-shadow: 1px 2px 0 #ecf0f1, 1px -2px 0 #ecf0f1, -1px 2px 0 #ecf0f1, -1px -2px 0 #ecf0f1, 2px 1px 0 #ecf0f1, 2px -1px 0 #ecf0f1, -2px 1px 0 #ecf0f1, -2px -1px 0 #ecf0f1, 1px 1px 0 #ecf0f1, 1px -1px 0 #ecf0f1, -1px 1px 0 #ecf0f1, -1px -1px 0 black; */
    filter: progid:DXImageTransform.Microsoft.Glow(Color=$base-grey-lite, Strength=1);
    -ms-filter: "progid:DXImageTransform.Microsoft.dropshadow(OffX=1, OffY=-1, Color=$base-grey-lite)progid:DXImageTransform.Microsoft.dropshadow(OffX=-1, OffY=-1, Color=$base-grey-lite)progid:DXImageTransform.Microsoft.dropshadow(OffX=1, OffY=0, Color=$base-grey-lite)progid:DXImageTransform.Microsoft.dropshadow(OffX=-1, OffY=0, Color=$base-grey-lite)progid:DXImageTransform.Microsoft.dropshadow(OffX=0, OffY=1, Color=$base-grey-lite)progid:DXImageTransform.Microsoft.dropshadow(OffX=1, OffY=1, Color=$base-grey-lite)progid:DXImageTransform.Microsoft.dropshadow(OffX=-1, OffY=1, Color=$base-grey-lite)";
}

/* ################  ################ */
.gl-article h2{
    margin-bottom :20px;
}


/* ################ header-logo ################ */
.header-logo{
    margin :10px 0px 10px 20px;
}

.header-logo img{
    max-width :80px;
    background-color :white;
}



/* ################ mobile ################ */
/* .gl-navbar-brand{ */
/*     float: left; */
/*     margin-left: -15px; */
/* } */
/* .gl-navbar li { */

/*     float: left; */
/*     display: inline-block;     */
/* } */


/* ################ 汎用 ################ */
#gl-hannyou .header-text {
    position: absolute;
    top: 55%;
    left: 43%;
    line-height: 0;
    font-size: 5vw;
    font-weight: 700;
}


/* ################ gl6 ################ */
.gl6-header-image{
    margin-bottom: 10px !important;
}





/* ################ fixedsticky.js ################ */
.fixedsticky {
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;
    position: sticky;
}


#gl-navbar{
    top: 0;
}




/* When position: sticky is supported but native behavior is ignored */
.fixedsticky-withoutfixedfixed .fixedsticky-off,
.fixed-supported .fixedsticky-off {
    position: static;
}
.fixedsticky-withoutfixedfixed .fixedsticky-on,
.fixed-supported .fixedsticky-on {
    position: fixed;
}
.fixedsticky-dummy {
    display: none;
}
.fixedsticky-on + .fixedsticky-dummy {
    display: block;
}


#google_map {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
}
#google_map iframe,
#google_map object,
#google_map embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

